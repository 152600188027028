/**
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
body.dark {
  .mdc-list-item--with-leading-icon .mdc-list-item__start,
  .mdc-list-item__secondary-text {
    color: white !important;
  }
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.87) !important;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin: 0 !important;
  line-height: 24px !important;
}

.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: auto !important;
}

.mdc-list-item__secondary-text::before {
  height: auto !important;
}

.mdc-list-item__content {
  padding-left: 6px;
}

.mdc-list-item {
  margin-bottom: 8px;
}
