/**
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
@use 'sass:map';
@use '@angular/material' as mat;

@mixin expansion-panel($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  ozgcloud-expansion-panel h3 {
    color: mat.m2-get-color-from-palette($primary-palette);
  }
}

ozgcloud-expansion-panel {
  .mat-expansion-panel-header {
    padding: 0 !important;
    height: 44px !important;
  }

  h3 {
    margin-bottom: 0;
    font-weight: 500;
    margin-left: 8px;
    font-size: 1rem;
  }

  .mat-expansion-panel-body {
    padding: 0 0 16px 56px;
  }
}

alfa-expansion-panel-with-user {
  .mat-expansion-panel-body {
    padding: 0 0 0 32px;
  }
}

.section.wiedervorlagen {
  .mat-expansion-panel-body .mat-expansion-panel-body {
    padding: 0 0 0 24px;
  }
}

.dark ozgcloud-expansion-panel {
  h3 {
    color: mat.m2-get-color-from-palette($primaryPalette, lighter);
  }
}
