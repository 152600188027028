/**
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
@use 'sass:map';
@use '@angular/material' as mat;

@mixin button($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');

  ozgcloud-button-with-spinner,
  ozgcloud-stroked-button-with-spinner {
    .mat-primary svg path {
      fill: mat.m2-get-color-from-palette($primary);
    }
  }

  .mdc-button {
    mat-icon {
      font-size: 24px !important;
      width: 24px !important;
      height: 24px !important;
    }
  }

  .mat-mdc-unelevated-button > .mat-icon,
  .mat-mdc-raised-button > .mat-icon,
  .mat-mdc-outlined-button > .mat-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
