/**
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
.mat-mdc-snack-bar-container {
  ozgcloud-snackbar-info > button {
    color: #2196f3 !important;
    height: 24px !important;
  }
}

.mat-mdc-snack-bar-container.error {
  .mdc-snackbar__surface {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .mdc-snackbar__label {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    color: black !important;
  }

  .mat-mdc-button-persistent-ripple,
  .mat-mdc-button-ripple {
    display: none;
  }

  min-width: 240px;
  max-width: 90vw;

  border: 3px solid mat.m2-get-color-from-palette($warnPalette);
  border-radius: 4px;
  background-color: lighten(mat.m2-get-color-from-palette($warnPalette), 38%);
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  color: rgba(#000, 0.89);
  position: relative;

  &:before {
    position: absolute;
    content: url('/assets/icons/error_outline_white.svg');
    display: block;
    background-color: mat.m2-get-color-from-palette($warnPalette);
    left: 0;
    top: 0;
    padding: 11px 9px 11px 7px;
    color: #fff;
    height: 100%;
  }

  > div {
    margin-left: 44px;
  }
}
