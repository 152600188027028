/**
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
alfa-vorgang-search {
  .mdc-text-field--filled,
  .mdc-text-field--filled:hover {
    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom: none;
    }
  }

  .mat-mdc-form-field-focus-overlay,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }
}

ozgcloud-fixed-dialog {
  .mat-mdc-form-field-focus-overlay,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }

  .mdc-text-field {
    padding: 0;
  }
}

body.dark {
  mat-form-field {
    --mdc-theme-error: theme('colors.error');
    --mat-form-field-error-text-color: theme('colors.error');
    --mdc-filled-text-field-error-focus-label-text-color: theme('colors.error');
    --mdc-outlined-text-field-error-focus-label-text-color: theme('colors.error');
    --mdc-filled-text-field-error-label-text-color: theme('colors.error');
    --mdc-outlined-text-field-error-label-text-color: theme('colors.error');
    --mdc-filled-text-field-disabled-active-indicator-color: theme('colors.error');
  }
}
