/**
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
@use 'variables';

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 365px !important;
  background-color: $background;
}

body.dark {
  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    background-color: $darkBackgroundExpansionPanelLike;
  }
}

alfa-help-menu {
  .mat-mdc-fab {
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 10px;
    height: 40px;
    width: auto;
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}
