@import '@angular/cdk/overlay-prebuilt.css';
@import 'typeface-roboto/index.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  }
}

:root {
  --warning: 38 92% 50%;
  --color-error: 0 71% 49%;
  --color-focus: 212 64% 47%;

  --color-disabled: 206 14% 95%;
  --color-disabled-dark: 208 12% 65%;

  --color-destructive: 360, 71%, 49%, 1;
  --color-destructive-hover: 360, 71%, 49%, 0.07;
  --color-destructive-primary-hover: 360, 71%, 49%, 0.9;

  --color-ghost-hover: 212, 80%, 42%, 0.07;

  --color-background-secondary: 0 0% 98%;
  --color-mainbg: 0 0% 100%;
  --text: 0 0% 0%;
  --color-text-white: 0 0% 100%;

  --color-primary-600: 212 80% 42%;
  --color-primary-600-hover: 212, 80%, 42%, 0.9;
  --color-background-50: 0 0% 100%;
  --color-background-100: 0 0% 98%;
  --color-background-150: 0 0% 95%;
  --color-background-200: 0 0% 90%;

  --color-grayborder: 0 0% 92%;

  --color-bewilligt-100: 122 100% 92%;
  --color-bewilligt-700: 122 100% 29%;

  --color-abgelehnt-100: 0 100% 92%;
  --color-abgelehnt-500: 0 100% 60%;

  --color-pdf: 4 62% 63%;
  --color-doc: 219 63% 54%;

  --color-modal-bg: 0 0% 100%;
  --color-dropdown-bg: 0 0% 98%;
  --color-gray-backdrop: 0, 0%, 90%, 0.95;
}

.dark {
  --color-error: 0 99% 72%;
  --color-focus: 43 100% 48%;

  --color-disabled: 206 14% 15%;
  --color-disabled-dark: 208 12% 33%;

  --color-destructive: 360, 71%, 49%, 1;
  --color-destructive-hover: 360, 71%, 49%, 0.2;
  --color-destructive-primary-hover: 360, 71%, 49%, 0.9;

  --color-ghost-hover: 43, 96%, 58%, 0.18;

  --color-background-secondary: 0 0% 16%;
  --color-mainbg: 0 0% 14%;
  --text: 0 0% 100%;
  --color-text-white: 0 0% 0%;

  --color-primary-600: 43 96% 58%;
  --color-primary-600-hover: 43, 96%, 58%, 0.9;
  --color-background-50: 0 0% 0%;
  --color-background-100: 0 0% 8%;
  --color-background-150: 0 0% 11%;
  --color-background-200: 0 0% 16%;

  --color-grayborder: 0 0% 40%;

  --color-bewilligt-100: 122 100% 22%;
  --color-bewilligt-700: 122 100% 29%;

  --color-abgelehnt-100: 0 100% 22%;
  --color-abgelehnt-500: 0 100% 60%;

  --color-pdf: 4 62% 63%;
  --color-doc: 219 63% 54%;

  --color-modal-bg: 0 0% 26%;
  --color-dropdown-bg: 0 0% 26%;
  --color-gray-backdrop: 0, 0%, 26%, 0.95;
}

.bescheid-dialog-backdrop {
  @apply bg-gray-500 bg-opacity-30 transition-opacity;
}

.blur-dialog-backdrop {
  @apply bg-gray-400 bg-opacity-75 backdrop-blur-sm transition-opacity dark:bg-gray-500 dark:bg-opacity-75;
}

.heading-1 {
  @apply text-3xl font-medium text-text;
}

.heading-2 {
  @apply py-4 text-2xl font-medium text-text;
}
